*{
    margin: 0;
    padding: 0;
    
}

.contactContainer { 
    
    background-image: linear-gradient( 115deg, rgba(27, 27, 31, 0.8), rgba(78, 78, 116, 0.7) ),
    url("../../images/mp9.jpg");
background-repeat: no-repeat;
background-position: center;
background-size: cover;
height: 100%;
width: 100%;

/* top: 0;
left: 0;
height: 100%;
width: 100%;
z-index: -1; */

}
/* h1, .contacts{
    margin-top: 0;
    padding-top: 15px;
    font-family: Poppins;
    font-weight: 600;
    color:white;
 fixes spaces ie margin...
}

p, .bottomHeader{
    color: white;
    font-style: italic;
    font-weight: 200;
    text-shadow: 1px 1px 1px rgb(0 0 0 / 40%);
} */

.userInput{
    display: block;
    width: 100%;
    height: 2.375rem;
    padding: 0.375rem 0.75rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.bottomHeader{
    text-align: center;
}

Form{
    background-color: rgb(255,255, 255, 50%);
    margin-left: auto;
    margin-right: auto;
    width:65%;
    border-radius: 0.25rem;
    padding: 2.5rem;
}

.formGroup{
    /* margin-top: 25px;
    padding: 10px 0px;
    margin-bottom: 10px; */
    margin: 0 auto 1.25rem auto;
    padding: 0.25rem;
}

label{
    display: flex;
    color: white;
    font-weight: 600;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
}

.subButton{
    
    
    
    cursor: pointer;
    border: none;
    display: block;
    width: 100%;
    height: 2.375rem;
    padding: 0.375rem 0.75rem;
    color: white;
    background-color: #1955e6;
    background-clip: padding-box;
    border: none;
    border-radius: 0.25rem;
}

.formContainer{
    padding-bottom: 200px;
}

textarea{
    min-height: 120px;
    /* width: 100%;
    padding: 0.625rem; */
    resize: none;
    

    display: block;
    width: 100%;
    height: 2.375rem;
    padding: 0.375rem 0.75rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


@media(min-width: 319px) {
    .contactContainer {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 100%;
        width: 100%;  
      }
  } 
  

  /* @media(min-width: 768px) {
    .contactContainer {
        max-width: 720px;
    }
  } */
  
