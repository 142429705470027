
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.leftIcon a {
  text-decoration: none;
}

li {
  /* display:inline-block; */
  list-style: none;
  margin-right: 1.5em;

}

.topNavContainer {
  background-color: #ec1c24;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-top: .90em;
}

.contentLeft {
  list-style: none;
  margin-left: 1.5em;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;


}

.contentRight {
  list-style: none;
  margin-right: 1.5em;
  display: flex;
  justify-content: space-around;
  /* flex-wrap: wrap; */

}

@media (max-width: 729px) {
  .contentLeft {
  display: flex;
  flex-direction: column;
  
  }
} 

@media (max-width: 729px) {
  .right {
  margin:auto;
  }
} 

@media (max-width: 729px){
  .topNavContainer {
    flex-wrap: wrap;
  }
}


/* @media (max-width: 525px) {
  .topNavContainer {

display: flex;
flex-direction: column; 
height: 140px; }
} */



/* @media (max-width: 792px) {
  .contentLeft {
    width: 100%;
    text-align: center;

    display: flex;


    flex: 2;

    height: 60px;
  }
}

@media (max-width: 825px) {
  header {

    height: 5.5em;
  }
} */
.nav-links:hover{
        border-bottom: 10px;
   } 


  

/* Responsive */
@media(min-width: 310px) and (max-device-width : 355px){
    #logo{
      width: 210px;
      height: 32px;
  }
  }
  
  @media(min-width: 356px) and (max-device-width : 415px){
    #logo{
      width: 256px;
      height: 35px;
  }
  }

  @media(min-width: 279px) and (max-device-width : 309px){
    #logo{
      width: 170px;
      height: 28px;
  }
  }
.vid{
    object-fit: cover;
    width: 100%;
    height: 100%;
    /* height: 570px; */
    position: relative;
    z-index: -3;
}

h1{
   text-align: center;
   margin-top: 1.5em;
}

section {

   height: 80%;
   
}

.picDiv,.img {
   
   /* padding-top: 100px; */
   /* text-align: center; */
   background-color: white;
}

.picDiv{

border: 3px solid grey;

}

.landingText {
    /* text-align:center; */
    /* position: relative; */
    color: black;
    background-color: #ffffff;
    /* background-color: #f5f6f7; */
    /* background: rgb(33,33,33, 1); */
    /* padding: 40px;
    margin-bottom: 0px;
    width: 100%;
    font-size: 30px; */
    display: grid;
    grid-template-rows:  300px 300px;
    grid-template-columns: 300px 300px 300px;   
    grid-gap: 10px;   
    gap: 10px;
    justify-content: center;
    padding-top: 20px;

 }

 .my-grid{
   height: 700px;
   margin-top: -90px; 
   width: 100%;

 }
 

 .box{
    /* background: #444;
    border: 1px solid #555;
    display: flex; */
    align-items: center;
    justify-content: center;
    /* color: #AAA; */
  }
 

 #landingIconContainer {
    text-align: center;
    justify-content: center;
    position: relative;
    margin-top: 0px;
    padding: 40px;
    width: 100%;
    background-color: rgb(236, 28, 36);
    
 }
 
 
 /* @media(max-width: 1000px){
   section {
     height: 50%;
 }
 } */
.mapContainer{
    margin: 20px 20px 40px 20px;
    
}

h1{
    padding-bottom: 10px;
    padding-top: 0px;
}


body{
	line-height: 1.5;
	font-family: 'Poppins', sans-serif;
}
*{
	margin:0;
	padding:0;
	box-sizing: border-box;
}
.container{
	max-width: 1170px;
	margin:auto;
    
}
.mrow{
	display: flex;
	flex-wrap: wrap;
}
ul{
	list-style: none;
    align-items: left;
}


.footer{
	background-color: #24262b;
    padding: 70px 0;
}
.footer-col{
   width: 25%;
   padding: 0 15px;
}
.footer-col h4{
	font-size: 18px;
	color: #ffffff;
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 500;
	position: relative;
}
.footer-col h4::before{
	content: '';
	position: absolute;
	left:0;
	bottom: -10px;
	background-color: #ec1c24;
	height: 2px;
	box-sizing: border-box;
	width: 50px;
}
.footer-col ui li:not(:last-child){
	margin-bottom: 10px;
}
.footer-col ui li {
	font-size: 16px;
	/* text-transform: capitalize; */
	text-decoration: none;
	font-weight: 300;
	color: #bbbbbb;
	display: relative;
	transition: all 0.3s ease;
}
.footer-col ui li a{
	font-size: 16px;
	text-transform: capitalize;
	text-decoration: none;
	font-weight: 300;
	color: #bbbbbb;
	display: relative;
	transition: all 0.3s ease;
}
.footer-col ui li a:hover{
	color: #ffffff;
	padding-left: 8px;
}
.footer-col .social-links a{
	display: inline-block;
	height: 40px;
	width: 40px;
	background-color: rgba(255,255,255,0.2);
	margin:0 10px 10px 0;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	color: #ffffff;
	transition: all 0.5s ease;
}
.footer-col .social-links a:hover{
	color: #24262b;
	background-color: #ffffff;
}

/*responsive*/
@media(max-width: 767px){
  .footer-col{
    width: 50%;
    margin-bottom: 30px;
}
}
@media(max-width: 574px){
  .footer-col{
    width: 100%;
}
}





.ReleaseImages{
        width:100%;
        height:75%;
        margin: auto;
      
}

@media (min-width: 1100px) {
  .ReleaseImages {
    width:80%;
    height:40%;
  }
} 


.contentDiv{
 align-items: center;
 height: 100%;
}

.privacyDiv{
    width: 1000px;
    margin: auto;
    position: relative;
    padding-bottom: 10px;
    padding-top: 12px;

}
   

    .privacyTitle{
    text-align: center;
}

.link{
color: black;
text-decoration: underline;}

@media(max-width: 1020px){
    .contentDiv{
        align-items: center;
        height: 100%;
      }
    
  }
  @media(max-width: 574px){
    .contentDiv{
        align-items: center;
        height: 100%;
    
    }
    
  }
  @media(min-width: 274px){
    .contentDiv{
        align-items: center;
        height: 100%;
    
    }
    
  }


@media(max-width: 1020px){
    .privacyDiv{
        width: 480px;
        align-items: center;
        margin: auto;
        padding-bottom: 10px;
        padding-top: 12px;
    }
  }
  @media(max-width: 574px){
    .privacyDiv{
        width: 290px;
        align-items: center;
        margin: auto;
        padding-bottom: 10px;
        padding-top: 12px;
    }
  }
.aboutContainer{
    align-items: center;
    height: 100%;

}


.contentAbout{
    width: 800px;
    align-items: center;
    margin: auto;
    padding-top: 12px;
}
.aboutTitle{
    text-align: center;
}

p{
    font-family: Montserrat;
    font-size: 18px;
}

.aboutTitle{
    font-size: 32px;
    font-weight: 600;
    font-family: Rajdhani,Arial,Helvetica,sans-serif;
}


@media(max-width: 890px){
    .aboutContainer{
        align-items: center;
        height: 100%;
    
    }
    
  }
  @media(max-width: 574px){
    .aboutContainer{
        align-items: center;
        height: 100%;
    
    }
    
  }


@media(max-width: 890px){
    .contentAbout{
        width: 400px;
        align-items: center;
        margin: auto;
        padding-top: 12px;
    }
  }
  @media(max-width: 574px){
    .contentAbout{
        width: 290px;
        align-items: center;
        margin: auto;
        padding-top: 12px;
    }
  }
.centered {
    position: absolute;
    top: 25%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-weight: 900;
    font-size: 34px;
    
  }
  .eventContainer{
      position: relative;
      text-align: center;
      color: white;
      height: 100%;
    
  }

*{
    margin: 0;
    padding: 0;
    
}

.contactContainer { 
    
    background-image: linear-gradient( 115deg, rgba(27, 27, 31, 0.8), rgba(78, 78, 116, 0.7) ),
    url(/static/media/mp9.ac3a49ec.jpg);
background-repeat: no-repeat;
background-position: center;
background-size: cover;
height: 100%;
width: 100%;

/* top: 0;
left: 0;
height: 100%;
width: 100%;
z-index: -1; */

}
/* h1, .contacts{
    margin-top: 0;
    padding-top: 15px;
    font-family: Poppins;
    font-weight: 600;
    color:white;
 fixes spaces ie margin...
}

p, .bottomHeader{
    color: white;
    font-style: italic;
    font-weight: 200;
    text-shadow: 1px 1px 1px rgb(0 0 0 / 40%);
} */

.userInput{
    display: block;
    width: 100%;
    height: 2.375rem;
    padding: 0.375rem 0.75rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.bottomHeader{
    text-align: center;
}

Form{
    background-color: rgb(255,255, 255, 50%);
    margin-left: auto;
    margin-right: auto;
    width:65%;
    border-radius: 0.25rem;
    padding: 2.5rem;
}

.formGroup{
    /* margin-top: 25px;
    padding: 10px 0px;
    margin-bottom: 10px; */
    margin: 0 auto 1.25rem auto;
    padding: 0.25rem;
}

label{
    display: flex;
    color: white;
    font-weight: 600;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
}

.subButton{
    
    
    
    cursor: pointer;
    border: none;
    display: block;
    width: 100%;
    height: 2.375rem;
    padding: 0.375rem 0.75rem;
    color: white;
    background-color: #1955e6;
    background-clip: padding-box;
    border: none;
    border-radius: 0.25rem;
}

.formContainer{
    padding-bottom: 200px;
}

textarea{
    min-height: 120px;
    /* width: 100%;
    padding: 0.625rem; */
    resize: none;
    

    display: block;
    width: 100%;
    height: 2.375rem;
    padding: 0.375rem 0.75rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


@media(min-width: 319px) {
    .contactContainer {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 100%;
        width: 100%;  
      }
  } 
  

  /* @media(min-width: 768px) {
    .contactContainer {
        max-width: 720px;
    }
  } */
  

