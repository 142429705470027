.mapContainer{
    margin: 20px 20px 40px 20px;
    
}

h1{
    padding-bottom: 10px;
    padding-top: 0px;
}

