h1{
   text-align: center;
   margin-top: 1.5em;
}

section {

   height: 80%;
   
}

.picDiv,.img {
   
   /* padding-top: 100px; */
   /* text-align: center; */
   background-color: white;
}

.picDiv{

border: 3px solid grey;

}

.landingText {
    /* text-align:center; */
    /* position: relative; */
    color: black;
    background-color: #ffffff;
    /* background-color: #f5f6f7; */
    /* background: rgb(33,33,33, 1); */
    /* padding: 40px;
    margin-bottom: 0px;
    width: 100%;
    font-size: 30px; */
    display: grid;
    grid-template-rows:  300px 300px;
    grid-template-columns: 300px 300px 300px;   
    gap: 10px;
    justify-content: center;
    padding-top: 20px;

 }

 .my-grid{
   height: 700px;
   margin-top: -90px; 
   width: 100%;

 }
 

 .box{
    /* background: #444;
    border: 1px solid #555;
    display: flex; */
    align-items: center;
    justify-content: center;
    /* color: #AAA; */
  }
 

 #landingIconContainer {
    text-align: center;
    justify-content: center;
    position: relative;
    margin-top: 0px;
    padding: 40px;
    width: 100%;
    background-color: rgb(236, 28, 36);
    
 }
 
 
 /* @media(max-width: 1000px){
   section {
     height: 50%;
 }
 } */