.aboutContainer{
    align-items: center;
    height: 100%;

}


.contentAbout{
    width: 800px;
    align-items: center;
    margin: auto;
    padding-top: 12px;
}
.aboutTitle{
    text-align: center;
}

p{
    font-family: Montserrat;
    font-size: 18px;
}

.aboutTitle{
    font-size: 32px;
    font-weight: 600;
    font-family: Rajdhani,Arial,Helvetica,sans-serif;
}


@media(max-width: 890px){
    .aboutContainer{
        align-items: center;
        height: 100%;
    
    }
    
  }
  @media(max-width: 574px){
    .aboutContainer{
        align-items: center;
        height: 100%;
    
    }
    
  }


@media(max-width: 890px){
    .contentAbout{
        width: 400px;
        align-items: center;
        margin: auto;
        padding-top: 12px;
    }
  }
  @media(max-width: 574px){
    .contentAbout{
        width: 290px;
        align-items: center;
        margin: auto;
        padding-top: 12px;
    }
  }