.nav-links:hover{
        border-bottom: 10px;
   } 


  

/* Responsive */
@media(min-width: 310px) and (max-device-width : 355px){
    #logo{
      width: 210px;
      height: 32px;
  }
  }
  
  @media(min-width: 356px) and (max-device-width : 415px){
    #logo{
      width: 256px;
      height: 35px;
  }
  }

  @media(min-width: 279px) and (max-device-width : 309px){
    #logo{
      width: 170px;
      height: 28px;
  }
  }