.centered {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 900;
    font-size: 34px;
    
  }
  .eventContainer{
      position: relative;
      text-align: center;
      color: white;
      height: 100%;
    
  }
