.ReleaseImages{
        width:100%;
        height:75%;
        margin: auto;
      
}

@media (min-width: 1100px) {
  .ReleaseImages {
    width:80%;
    height:40%;
  }
} 

