* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.leftIcon a {
  text-decoration: none;
}

li {
  /* display:inline-block; */
  list-style: none;
  margin-right: 1.5em;

}

.topNavContainer {
  background-color: #ec1c24;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-top: .90em;
}

.contentLeft {
  list-style: none;
  margin-left: 1.5em;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;


}

.contentRight {
  list-style: none;
  margin-right: 1.5em;
  display: flex;
  justify-content: space-around;
  /* flex-wrap: wrap; */

}

@media (max-width: 729px) {
  .contentLeft {
  display: flex;
  flex-direction: column;
  
  }
} 

@media (max-width: 729px) {
  .right {
  margin:auto;
  }
} 

@media (max-width: 729px){
  .topNavContainer {
    flex-wrap: wrap;
  }
}


/* @media (max-width: 525px) {
  .topNavContainer {

display: flex;
flex-direction: column; 
height: 140px; }
} */



/* @media (max-width: 792px) {
  .contentLeft {
    width: 100%;
    text-align: center;

    display: flex;


    flex: 2;

    height: 60px;
  }
}

@media (max-width: 825px) {
  header {

    height: 5.5em;
  }
} */