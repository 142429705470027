.contentDiv{
 align-items: center;
 height: 100%;
}

.privacyDiv{
    width: 1000px;
    margin: auto;
    position: relative;
    padding-bottom: 10px;
    padding-top: 12px;

}
   

    .privacyTitle{
    text-align: center;
}

.link{
color: black;
text-decoration: underline;}

@media(max-width: 1020px){
    .contentDiv{
        align-items: center;
        height: 100%;
      }
    
  }
  @media(max-width: 574px){
    .contentDiv{
        align-items: center;
        height: 100%;
    
    }
    
  }
  @media(min-width: 274px){
    .contentDiv{
        align-items: center;
        height: 100%;
    
    }
    
  }


@media(max-width: 1020px){
    .privacyDiv{
        width: 480px;
        align-items: center;
        margin: auto;
        padding-bottom: 10px;
        padding-top: 12px;
    }
  }
  @media(max-width: 574px){
    .privacyDiv{
        width: 290px;
        align-items: center;
        margin: auto;
        padding-bottom: 10px;
        padding-top: 12px;
    }
  }